<template>
  <div>
    <DataTable ref="dt" :value="portes" :paginator="true" :rows="constants.defaultRowNumber" @rowSelect="clickPorte" selection-mode="single"
               dataKey="id" :rowHover="true" v-model:filters="filters" filterDisplay="menu" :loading="loading"
               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="constants.rowsPerPageOptions"
               scrollable scroll-height="flex"
               :currentPageReportTemplate="$t('datatable.currentPageReportTemplate', {first: '{first}', last: '{last}', totalRecords: '{totalRecords}', target: $t('datatable.target.portes')})">
      <template #header>
        <div class="p-d-flex flex justify-content-between ml-2">
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filters['global'].value" :placeholder="$t('search')" />
          </span>
          <Button :disabled="portes.length === 0" :class="{ 'disabled-button': portes.length === 0 }"
                  class="h-auto"
                  :loading="exportSheetLoading" icon="pi pi-external-link" :label="$t('export')" @click="createSheet" />
        </div>
      </template>
      <template #empty>
        {{ $t('datatable.notFound', {target: $t('datatable.target.data')}) }}
      </template>
      <template #loading>
        {{ $t('datatable.load', {target: $t('datatable.target.data')}) }}
      </template>
      <Column field="last_name" :header="$t('lastname').toUpperCase()" sortable filterField="last_name" sortField="last_name"
              :showFilterMatchModes="false" class="set-column" :show-apply-button="false" :show-clear-button="false">
        <template #filter="{filterModel, filterCallback}">
          <div class="p-mb-3 p-text-bold mb-3">{{ $t('lastname') }}</div>
          <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="lastnamesFilter" option-value="value" optionLabel="value" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
            <template #option="slotProps">
              <div>
                <span>{{slotProps.option.value}}</span>
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column field="first_name" :header="$t('firstname').toUpperCase()" sortable filterField="first_name" sortField="first_name" :showFilterMatchModes="false" :show-apply-button="false" :show-clear-button="false">
        <template #filter="{filterModel, filterCallback}" class="set-column">
          <div class="p-mb-3 p-text-bold mb-3">{{ $t('firstname') }}</div>
          <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="firstnamesFilter" option-value="value" optionLabel="value" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
            <template #option="slotProps">
              <div>
                <span>{{slotProps.option.value}}</span>
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column field="email" :header="$t('email').toUpperCase()" sortable filterField="email" sortField="email" :showFilterMatchModes="false" :show-apply-button="false" :show-clear-button="false">
        <template #filter="{filterModel, filterCallback}">
          <div class="p-mb-3 p-text-bold mb-3">Email</div>
          <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="emailsFilter" option-value="value" optionLabel="value" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
            <template #option="slotProps">
              <div>
                <span>{{slotProps.option.value}}</span>
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column field="phone" :header="$t('phone').toUpperCase()" sortable filterField="phone" sortField="phone" :showFilterMatchModes="false"
              class="set-column" :show-apply-button="false" :show-clear-button="false">
        <template #body="{data}">
          {{data.phone?.replaceAll('-', '.')}}
        </template>
        <template #filter="{filterModel, filterCallback}">
          <div class="p-mb-3 p-text-bold mb-3">Téléphone</div>
          <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="phonesFilter" option-value="value" optionLabel="label" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
            <template #option="slotProps">
              <div>
                <span>{{slotProps.option.label}}</span>
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column field="status" :header="$t('status').toUpperCase()" sortable filterField="status" sortField="status" :showFilterMatchModes="false"
              class="set-column" :show-apply-button="false" :show-clear-button="false">
        <template #body="{data}">
          <Chip :class="'custom-chip-' + data.status" :label="getOptionLabel(constants.data.independentOptions, data.status)?.toUpperCase()"></Chip>
        </template>
        <template #filter="{filterModel, filterCallback}">
          <div class="p-mb-3 p-text-bold mb-3">Statut</div>
          <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="constants.data.independentOptions" option-value="value" optionLabel="label" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
            <template #option="slotProps">
              <div>
                <span :class="'badge status-' + slotProps.option.value">{{slotProps.option.label}}</span>
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import SheetGenerator from "@/utils/SheetGenerator";
import {getOptionLabel} from "@/utils/Misc";
import {mapState} from "vuex";
export default {
  name: "AdminTableauPorte",
  props: ['portes'],
  data() {
    return {
      customers: null,
      selectedCustomers: null,
      filters: {
        'global': {value: null, matchMode: null},
        'last_name': {value: null, matchMode: FilterMatchMode.IN},
        'first_name': {value: null, matchMode: FilterMatchMode.IN},
        'email': {value: null, matchMode: FilterMatchMode.IN},
        'phone': {value: null, matchMode: FilterMatchMode.IN},
        'status': {value: null, matchMode: FilterMatchMode.IN},
      },
      loading: true,
      exportSheetLoading: false
    }
  },
  computed: {
    ...mapState({
      constants: state => state.constants
    }),
    lastnamesFilter () {
      let array = this.portes.map((p) => p.last_name)
      let filterArray = []
      array.forEach((item) => {
        if (!filterArray.find((fa) => fa.value === item)) {
          filterArray.push({
            value: item
          })
        }
      })
      return filterArray
    },
    firstnamesFilter () {
      let array = this.portes.map((p) => p.first_name)
      let filterArray = []
      array.forEach((item) => {
        if (!filterArray.find((fa) => fa.value === item)) {
          filterArray.push({
            value: item
          })
        }
      })
      return filterArray
    },
    emailsFilter () {
      let array = this.portes.map((p) => p.email)
      let filterArray = []
      array.forEach((item) => {
        if (!filterArray.find((fa) => fa.value === item)) {
          filterArray.push({
            value: item
          })
        }
      })
      return filterArray
    },
    phonesFilter () {
      let array = this.portes.map((p) => p.phone)
      let filterArray = []
      array.forEach((item) => {
        if (!filterArray.find((fa) => fa.value === item)) {
          filterArray.push({
            value: item,
            label: item?.replaceAll('-', '.')
          })
        }
      })
      return filterArray
    },
  },
  mounted() {
    this.loading = false;
  },
  methods: {
    getOptionLabel,
    clickPorte (porte) {
      this.$router.push({ name: 'AdminDashboardPorte', params: { idPorte: porte.data.id }})
    },
    async createSheet () {
      this.exportSheetLoading = true
      // get directly the filtered and sorted data in the dataTable
      const ids = await this.$refs.dt.processedData.map(porte => porte.id)
      SheetGenerator(ids, '/rh/export-sheet', this)
          .then(() => {
            this.exportSheetLoading = false
          })
          .catch(() => {
            this.exportSheetLoading = false
          })
    }
  }
}
</script>

<style scoped>
.disabled-button {
  opacity: 0.6;
  background-color: grey;
  border-color: grey;
}
</style>



