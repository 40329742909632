<template>
  <div class="flex-container non-scrollable">
    <page-title value="Portés"/>
    <div class="scrollable">
      <admin-tableau-porte :portes="portes" />
    </div>
  </div>
</template>

<script>

import AdminTableauPorte from "@/components/Portes/AdminTableauPorte";
import PageTitle from "@/components/PageTitle.vue";
export default {
  name: "Portes",
  components: {PageTitle, AdminTableauPorte},
  data () {
    return {
      portes: [],
    }
  },
  created () {
    this.$store.dispatch('independent/getAllIndependents').then((res) => {
      this.portes = res
    })
  }
}
</script>

<style scoped>

</style>
